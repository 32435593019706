import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Header from '../components/Header'

function Mymusic() {
  return (
    <div className='bg-[#1C1C1C] h-[100vh]'>
      <Header/>
      <Hero/>
    </div>
  )
}

export default Mymusic
