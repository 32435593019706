import React from 'react'

function Cards({ totalEnteries ,activeUsersCount, pendingUsersCount}) {

  return (
    <div>
    
<div class="w-[100%] bg-[#181818] px-3 xs:px-6 pt-10 sm:px-16 lg:px-8 lg:pt-14 mx-auto">

  <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
 
    <div class=" flex-col bg-black hidden sm:flex shadow-sm rounded-xl  ">
      <div class="p-4 md:p-5 ">
        <div class=" items-center gap-x-2">
          <p class="sm:text-xl md:text-xl lg:text-2xl text-[12px] xs:text-sm uppercase mt-3   tracking-wide font-circular text-gray-100">
            Total Users
          </p>
          <p class="sm:text-xl md:text-xl lg:text-2xl text-[12px] xs:text-sm uppercase mt-3 ml-2   tracking-wide font-circular text-gray-100">
          {totalEnteries}
          </p>
        </div>

      
      </div>
    </div>
    <div class="flex sm:hidden flex-col bg-black  shadow-sm rounded-xl  ">
      <div class="p-4 md:p-5">
        <div class=" items-center gap-x-2">
        <p class="sm:text-xl md:text-xl text-[12px] lg:text-2xl xs:text-sm uppercase mt-3   tracking-wide font-circular text-gray-100">
            Total Users
          </p>
          <p class="sm:text-xl md:text-xl text-[12px] lg:text-2xl xs:text-sm uppercase mt-3 ml-2   tracking-wide font-circular text-gray-100">
          {totalEnteries}
          </p>
        </div>

      
      </div>
    </div>

    <div class="flex flex-col bg-black  shadow-sm  rounded-xl  ">
      <div class="p-4 md:p-5">
        <div class=" items-center gap-x-2">
          <p class="sm:text-xl md:text-xl text-[12px] lg:text-2xl xs:text-sm uppercase mt-3  tracking-wide font-circular text-gray-100">
            Active Users
          </p>
          <p class="sm:text-xl md:text-xl text-[12px] lg:text-2xl xs:text-sm uppercase mt-3 ml-2 tracking-wide font-circular text-gray-100">
            {activeUsersCount}
          </p>
        </div>

      
      </div>
    </div>
   
    <div class="flex flex-col bg-black  shadow-sm rounded-xl  ">
      <div class="p-4 md:p-5">
        <div class="items-center gap-x-2">
          <p class="sm:text-xl md:text-xl text-[12px] lg:text-2xl xs:text-sm uppercase mt-3  tracking-wide font-circular text-gray-100">
          Pending Users
          </p>
          <p class="sm:text-xl text-[12px] md:text-xl lg:text-2xl xs:text-sm uppercase mt-3 ml-2 tracking-wide font-circular text-gray-100">
         {pendingUsersCount}
          </p>
        </div>

      
      </div>
    </div>

 
  
  </div>

</div>

    </div>
  )
}

export default Cards
