import React, { useState, useEffect } from "react";
import privacy from "../assets/privacy.svg";
import eye2 from "../assets/eye2.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { setAdminToken, clearAdminToken } from '../Redux/actions/Tokenaction'; // Import your admin actions
import { connect } from 'react-redux';
import { API_URL } from "../constants";

// Import connect
// Define mapStateToProps to map the admin token from Redux state to props
const mapStateToProps = (state) => ({
  adminToken: state.admin.adminToken,
});

function Admin(props) {

  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  // const [adminToken, setAdminToken] = useState(localStorage.getItem('adminToken'));
  const [isToggled, setToggled] = useState(true);
  const [Loader, setLoader] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // const adminToken = props.adminToken;
  // State variables for error messages
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  // const adminToken = useSelector((state) => state.admin.adminToken);
  // const handleToggle = () => {
  //   setToggled((prevToggled) => !prevToggled);
  // };
  // const login =()=>{
  // localStorage.setItem('login',true)
  // }
  // const navigateTo = useNavigate();
  // useEffect(()=>{
  //   let admin =localStorage.getItem('admin');
  //   if(admin){
  //     navigateTo('/admin/dashboard')
  //   }
  // });
  useEffect(() => {
    // Add the viewport meta tag to disable zooming
    const viewport = document.querySelector('meta[name="viewport"]');
    viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');

    // The rest of your code...
  }, []);
  useEffect(() => {
    const storedToggleState = localStorage.getItem("toggleState");

    setToggled(storedToggleState ? JSON.parse(storedToggleState) : true);
  }, []);

  useEffect(() => {
    localStorage.setItem("toggleState", JSON.stringify(isToggled));
  }, [isToggled]);

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var flag = false
    // Reset error messages
    setEmailError("");
    setPasswordError("");

    // Validation for email
    if (!email) {
      setEmailError("Email is required");
      flag = true
    }

    // Validation for password
    if (!password) {
      setPasswordError("Password is required");
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters");
      flag = true
    }

    // If there are validation errors, do not proceed with the API request
    if (flag = false) {
      return;
    }
    setLoader(true)
    try {
      const response = await axios.post(`${API_URL}api/admin/login`, {
        email: email,
        password: password,
      });

      console.log("API Response:", response.data);

      if (response.data) {
        if (response.data) {

          // Use the setAdminToken action from Redux
          props.setAdminToken(response.data.token);
          console.log("Token set in Redux state:", response.data.token);
          // localStorage.setItem("adminToken", response.data.token);
          // console.log("Token set in localStorage:", response.data.token);

          // localStorage.setItem('admintoken', response.data.token);
          navigate("/admin/dashboard");
        }
      } else {
        // Handle login failure (incorrect email or password)
        console.error("Login failed:", response.data.message);
        setLoginError("Login failed. Incorrect email or password.");
      }
    } catch (error) {
      // Handle other errors, e.g., network issues
      console.error("Error submitting data:", error);
      if (error.response && error.response.status === 401) {
        // Unauthorized error (incorrect email or password)
        setLoginError("Login failed. Incorrect email or password.");
      } else {
        // Other error handling
        setLoginError("Login failed. Incorrect email or password.");
      }
      setLoader(false)
      props.clearAdminToken();
      navigate("/admin");
      // If there's an error, navigate to the '/admin' page
      // navigate("/admin");
    }
  };




  return (
    <div className="bg-[#121212] custom-scrollbar ">
      <Header />
      <body class=" font-circular bg-[#121212] w-[100%] flex justify-center py-0 pb md:py-9">
        <main class=" lg:w-[54%] w-[100%] md:w-[80%] ">
          <div class=" lg:bg-black bg-[#121212] rounded-xl shadow-sm ">
            <div class="p-5 sm:p-7">
              <div class="md:text-center text-start sm:px-0 md:px-14">
                <h1 class="block text-xl sm:text-2xl md:pt-14 pt-2 md:pb-10 font-bold lg:text-[44px] text-white dark:text-white">
                  Admin Spotify
                </h1>
              </div>

              <div class="mt-5 md:flex flex justify-start md:justify-center">
                <form className="md:w-[49%] w-[100%] ">
                  <div class="grid gap-y-4">
                    <div>
                      <label
                        for="email"
                        class="block tracking-wide text-white text-[12px] sm:text-[14px] mb-2 dark:text-white"
                      >
                        Email or Username
                      </label>
                      <div class="relative">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          class="md:py-3 py-2 l border text-white border-[#888888] bg-[#292929] text-[16px] px-4 block w-full hover:border-gray-200 placeholder:text-[16px] tracking-wide font-circularlight placeholder:font-circularlight placeholder:text-[#A7A7A7] placeholder:tracking-wide rounded-sm md:rounded-[0.3rem] text-sm focus:border-transparent  focus:outline-white focus:ring-0 focus:shadow-outline-blue"
                          placeholder="Email or username"
                          required
                          aria-describedby="email-error"
                        />
                      </div>
                      {emailError && <p className="text-xs font-circularlight text-red-600 mt-2">{emailError}</p>}

                    </div>

                    <div>
                      <label className="block tracking-wide font-bold text-white  text-[12px] sm:text-[14px]  mb-2 dark:text-white">
                        Password
                      </label>
                      <div className="relative flex  items-center  bg-[#292929] ">
                        <input
                          type={isPasswordVisible ? "text" : "password"}
                          id="password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="md:py-3 py-2 l border-[#888888] rounded-sm md:rounded-[0.3rem] border text-white text-[16px] bg-[#292929] px-4 block w-[100%] hover:border-gray-200 placeholder:text-[16px] tracking-wide font-circularlight placeholder:font-circularlight placeholder:text-[#A7A7A7] placeholder:tracking-wide  text-sm focus:border-transparent  focus:outline-white focus:ring-0 focus:shadow-outline-blue"
                          placeholder="Password"
                          required
                          aria-describedby="password-error"
                        />

                        <div className="absolute left-[90%]">
                          <img
                            className="bottom-3.5   3xl:w-7  cursor-pointer"
                            src={isPasswordVisible ? eye2 : privacy}
                            alt="Toggle Password Visibility"
                            onClick={togglePasswordVisibility}
                          />
                        </div>
                      </div>
                      {passwordError && <p className="text-xs font-circularlight text-red-600 mt-2">{passwordError}</p>}
                    </div>
                    {/* <div className="flex mt-1 items-center  ">
                      <label onClick={handleToggle} className="switch flex">
                        <input type="checkbox" checked={isToggled} />
                        <span className="slider round"></span>
                      </label>
                      <p className="text-white  text-[12px] sm:text-[14px]  font-circular tracking-wide ml-2">
                        Secure My Information
                      </p>
                    </div> */}
                    {loginError && <p className="text-xs font-circularlight text-red-600 mt-2">{loginError}</p>}
                    {Loader ? (<button
                      type="button"

                      disabled={true}
                      className="md:py-3.5 py-2 w-full tracking-wider mt-5 px-4 text md:text-[16px] inline-flex justify-center items-center gap-2 rounded-full border border-transparent font-medium md:font-semibold bg-[#1ED760] text-black transition-all text-[12px] sm:text-sm "
                    >
                      <div class="animate-spin inline-block w-5 h-5 border-[3px] border-current border-t-transparent text-green-600 rounded-full" role="status" aria-label="loading">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>) : (<button
                      type="button"

                      onClick={handleSubmit}
                      className="md:py-3.5 py-2 w-full tracking-wider mt-5 px-4 text md:text-[16px] inline-flex justify-center items-center gap-2 rounded-full border border-transparent font-medium md:font-semibold bg-[#1ED760] text-black transition-all text-[12px] sm:text-sm "
                    >
                      Submit
                    </button>)}
                  </div>
                </form>
              </div>
              <div className=" mt-10 mx-14 border-b border-[#292929] "></div>
              <div>
                <p className="md:text-[12px] text-center text-sm mt-6 tracking-wide font-circularlight text-[#a7a7a7] justify-center flex ">
                  This website uses secure TLS encryption to protect your data.
                </p>
              </div>
            </div>
          </div>
        </main>
      </body>
    </div>
  );
}

export default connect(mapStateToProps, { setAdminToken, clearAdminToken })(Admin);
