import React, { useContext, useEffect } from "react";
import Header from "../components/Header";
import Table from "../components/Table";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
const Tabs = () => {
  const adminToken = useSelector((state) => state.admin.adminToken); // Get adminToken from Redux
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Store the current location in localStorage before a page reload
    localStorage.setItem("lastLocation", location.pathname);

    // Add a listener to handle navigation when the component unmounts
    return () => {
      localStorage.removeItem("lastLocation");
    };
  }, [location.pathname]);

  if (!adminToken) {
    // If there's no token, you can display an error message or redirect to the login page.
    navigate("/admin");
    return <div>Error: You don't have access to this page.</div>;
  }

  return (
    <div className="custom-scrollbar ">
      <Header />
      <Table />
    </div>
  );
};

export default Tabs;
