import React from "react";
import { Link } from "react-router-dom";
function Faq() {
  return (
    <div className="w-[100%]  bg-[#121212] custom-scrollbar  pb-10">
      <h1 className="lg:text-[48px] sm:flex sm:flex-row  sm:justify-center flex flex-col pt-10 font-circular text-[#1ED760]  md:text-[40px] sm:text-3xl xs:text-xl 3xl:text-6xl text-2xl 3xl:leading-[80px]  lg:leading-[60px] mx-auto text-center  leading-10">
        ReUpgrade.me:<p className="md:text-[40px]   lg:leading-[65px] sm:text-3xl xs:text-xl  text-xl"> What to Expect</p>
      </h1>
     <div className="sm:w-[50%] w-[90%] flex justify-center mx-auto flex-col items-center">
     <div className=" flex text-center">
        <p className="lg:text-[20px]   px-5 font-circularlight mt-10  text-gray-200 md:text-[20px] xs:text-sm xs:leading-[20px]  sm:text-lg  lg:leading-[36px] 3xl:text-4xl 3xl:leading-[80px] text-sm leading-[20px] justify-start  mx-auto   ">
          {" "}
          <p className="text-[#1ED760] font-circular">
            {" "}
            Confirmation Email:
          </p>{" "}
          Shortly, you'll receive an email confirming your request. It will
          include all the details, including how long your upgrade will take.
        </p>
      </div>
      <div className=" flex text-center"> 
        <p className="lg:text-[20px]   px-5 font-circularlight mt-5  text-gray-200 md:text-[20px] xs:text-sm xs:leading-[20px]  sm:text-lg  lg:leading-[36px] 3xl:text-4xl 3xl:leading-[80px] text-sm leading-[20px] justify-start  mx-auto   ">
          {" "}
          <p className="text-[#1ED760] font-circular">
            {" "}
            Upgrading Process:
          </p>{" "}
          Our team of experts will carefully review your request and initiate
          the upgrade process. We'll keep you updated and provide more
          information when it's done.
        </p>
      </div>
      <div className=" flex text-center">
        <p className="lg:text-[20px]   px-5 font-circularlight mt-5  text-gray-200 md:text-[20px] xs:text-sm xs:leading-[20px]  sm:text-lg  lg:leading-[36px] 3xl:text-4xl 3xl:leading-[80px] text-sm leading-[20px] justify-start  mx-auto   ">
          {" "}
          <p className="text-[#1ED760] font-circular">
            {" "}
            Questions or Need Help?
          </p>{" "}
          If you have any questions or need assistance, our friendly customer support team is here for you. You can find our contact details in the confirmation email.
          
        </p>
      </div>

      <div className=" flex text-center">
        <p className="lg:text-[20px] items-start   px-5 font-circularlight mt-5  text-gray-200 md:text-[20px] xs:text-sm xs:leading-[20px]  sm:text-lg  lg:leading-[36px] 3xl:text-4xl 3xl:leading-[80px] text-sm leading-[20px] justify-start  mx-auto   ">
          {" "}
          <p className="text-[#1ED760] font-circular">
            {" "}
            Contact Us on WhatsApp:
          </p>{" "}
          
          Easily connect with us via WhatsApp. Click [here](https://wa.me/yourphonenumber) or scan the QR code below.
         
        </p>
      </div>
      <div className=" flex text-center">
        <p className="lg:text-[20px] items-start  px-5 font-circularlight mt-5  text-gray-200 md:text-[20px] xs:text-sm xs:leading-[20px]  sm:text-lg  lg:leading-[36px] 3xl:text-4xl 3xl:leading-[80px] text-sm leading-[20px] justify-start  mx-auto   ">
          {" "}
          <p className="text-[#1ED760] font-circular">
            {" "}
            Contact Us on Telegram:
          </p>{" "}
       
          Reach us on Telegram for support. Click [here](https://t.me/yourusername) or scan the QR code below. We appreciate your trust in ReUpgrade.me. 
        </p>
      </div>
      <div className=" flex text-center">
        <p className="lg:text-[20px] items-start  px-5 font-circularlight mt-5  text-gray-200 md:text-[20px] xs:text-sm xs:leading-[20px]  sm:text-lg  lg:leading-[36px] 3xl:text-4xl 3xl:leading-[80px] text-sm leading-[20px] justify-start  mx-auto   ">
          {" "}
          
       
           Our goal is to ensure a smooth and efficient upgrade experience for you, so you can enjoy the benefits promptly.
        </p>
      </div>
      <Link to='/thankyou'>
    <button
        type="button"
        className="md:py-3.5 py-2 px-10  tracking-wider mt-5  text md:text-[16px] inline-flex justify-center items-center gap-2 rounded-full border border-transparent font-medium md:font-semibold bg-[#1ED760] text-black transition-all text-[12px] sm:text-sm dark:focus:ring-offset-gray-800"
      >
        Return
      </button>
    </Link>
     </div>
    </div>
  );
}

export default Faq;

