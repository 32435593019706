import "./App.css";
import Login from "./view/Login";
import Dashboard from "./view/Dashboard";
import Users from "./view/Users";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Admin from "./view/Admin";
import Header from "./components/Header";
import PrivateRoute from "./components/PrivateRoute";
import { useSelector } from 'react-redux'; 
import Faq from "./components/Faq";
import Navbar from "./components/Navbar";
import Mymusic from "./view/Mymusic";
import Selectsrc from "./view/Selectsrc";
import Selectplaylist from "./view/Selectplaylist";

// import { AuthProvider } from './AuthContext';
// import { AuthProvider } from './AuthContext';
function App() {
  const adminToken = useSelector((state) => state.admin.adminToken); // Get adminToken from Redux
  return (
    
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/admin/dashboard"
          element={
            <PrivateRoute
              element={<Dashboard />}
              token={adminToken}
              redirect="/admin" // Redirect to the login page if no token is present
            />
          }
        />
        <Route path="/thankyou" element={<Users />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/header" element={<Header />} />
        <Route path="/thankyou/faq" element={<Faq />} />
        <Route path="/mymusic" element={<Mymusic />} />
        <Route path="/mymusic/selectmusic" element={<Selectsrc />} />
        <Route path="/mymusic/selectmusic/selectplaylist" element={<Selectplaylist />} />
      </Routes>
    </Router>
 
  );
}

export default App;
