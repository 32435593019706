import React, { useState, useEffect } from "react";
import trash from "../assets/trash-can-solid.svg";
import copy from "../assets/copy.png";
import eye from "../assets/privacy.svg";
import eye2 from "../assets/eye2.svg";
import axios from "axios";
import Cards from "./Cards";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../constants";

function Table() {
  const [users, setUsers] = useState([]);
  const [change, setChange] = useState(true);
  const [showAll, setShowAll] = useState(true);
  const [showActive, setShowActive] = useState(true);
  const [showPending, setShowPending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState();
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);
  const [copiedImageIndex, setCopiedImageIndex] = useState(null);
  const totalPages = Math.ceil(users.length / itemsPerPage);
  const [dltLoader , setdltloader] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  useEffect(() => {
    if (change) fetchData();
  }, [change]);


  const handleCancelDelete = () => {
    setShowDeletePopup(false);
  };
  
  const handleConfirmDelete = (userId) => {
    setDeleteLoading(true); // Set loading to true when the delete button is clicked
    handleDelete(userId)
      .then(() => {
        setDeleteLoading(false); // Reset loading state when deletion is complete
        setShowDeletePopup(false);
      })
      .catch((error) => {
        setDeleteLoading(false); // Reset loading state on error
        console.error("Error deleting user:", error);
      });
  };
  const fetchData = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const token = adminToken;
      const response = await axios.get(`${API_URL}api/admin/accounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data.reverse());
      setCurrentPage(lastPage);
      setChange(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading back to false whether there was success or an error
    }
  };
  const [divVisible, setDivVisible] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const toggleDiv = () => {
    setDivVisible(!divVisible);
    // Reset selectedCheckbox when the "All" checkbox is checked
    if (!divVisible) {
      setSelectedCheckbox(null);
    }
  };
  const [visiblePasswords, setVisiblePasswords] = useState(
    Array(users.length).fill(false)
  );

  const handlePasswordVisibility = (index) => {
    const updatedPasswords = [...visiblePasswords];
    updatedPasswords[index] = !updatedPasswords[index];
    setVisiblePasswords(updatedPasswords);
  };

  const handleStatusClick = async (id, status) => {
    setLoadingId(id);
    const token = adminToken;
    setLoading(true);
    const updatedStatus = status === false ? true : false;
    setLastPage(currentPage);
    // Send a PATCH request to update the status in the database
    try {
      const res = await axios.patch(
        `${API_URL}api/admin/accounts/status/${id}`,
        { status: updatedStatus }, // Send the updated status
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        // setChange(true);
        setUsers((prevUsers)=>{
          const updatedUsers=[...prevUsers]
          const userIndex = updatedUsers.findIndex((user)=>user._id === id);
          if(userIndex !== -1){
            updatedUsers[userIndex].status = updatedStatus
          }
          return updatedUsers;
        });
        // Update local storage with the new data
      } else {
        // Handle the case where the server request was not successful
        console.error("Server response indicates an error");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setLoadingId();
      setLoading(false); // Set loading back to false whether there was success or an error
    }
  };

  // Function to handle page changes
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(currentPage + 1);
      setCurrentPage(currentPage - 1);
      setCurrentPage(newPage);
    }
  };

  // const updateLocalStorage = (data) => {
  //   localStorage.setItem("userStatus", JSON.stringify(data));
  // };
  const handleCheckboxChange = (checkbox) => {
    setShowAll(false);
    setShowActive(false);
    setShowPending(false);

    if (checkbox === "all") {
      setShowAll(true);
    } else if (checkbox === "active") {
      setShowActive(true);
    } else if (checkbox === "pending") {
      setShowPending(true);
    }
  };
  const totalEnteries = users.length;
  console.log(totalEnteries);
  const activeUsersCount = users.filter(
    (userData) => userData.status === true
  ).length;
  const pendingUsersCount = users.filter(
    (userData) => userData.status === false
  ).length;

  const handleDelete = async (userId) => {
    
    try {
      // setdltloader(true)
      const token = adminToken;
      setLastPage(currentPage);
      await axios.delete(`${API_URL}api/admin/accounts/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      fetchData();
    } catch (error) {
      
      console.error("Error deleting data:", error);
    }
  };
  const handleCopyCredentials = (credentials, index) => {
    const textArea = document.createElement("textarea");
    textArea.value = credentials;

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select and copy the text inside the textarea
    textArea.select();
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textArea);

    // Set the copied image index
    setCopiedImageIndex(index);

    // Reset the opacity after 2 seconds
    setTimeout(() => {
      setCopiedImageIndex(null);
    }, 2000);
  };

  const adminToken = useSelector((state) => state.admin.adminToken);
  const navigate = useNavigate();

  if (!adminToken) {
    // If there's no token, you can display an error message or redirect to the login page.
    navigate("/admin");
    return <div>Error: You don't have access to this page.</div>;
  }

  // const handleAddUser = async (newUser) => {
  //   try {
  //     const token = adminToken;
  //     const response = await axios.post("http://localhost:4000/api/admin/accounts", newUser, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     // Prepend the new user data to the beginning of the 'users' array
  //     setUsers((prevUsers) => [response.data, ...prevUsers]);

  //     // Other actions as needed...
  //   } catch (error) {
  //     console.error("Error adding a new user:", error);
  //   }
  // }

  return (
    <div className="h-[100vh] bg-[#181818]">
{showDeletePopup && (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60">
    <div className="bg-[#181818] p-10 rounded-md shadow-md">
      <p className="text-gray-200 font-sftext">Are you sure you want to delete this user?</p>
      <div className="mt-8 flex items-center justify-center">
        <button
          className="mr-4 px-4 py-2 font-sftext text-gray-600 bg-gray-200 hover:bg-gray-300 rounded-md"
          onClick={handleCancelDelete}
        >
          Cancel
        </button>
        {deleteLoading ? (
  <button className=" px-7 py-2 font-sftext text-white bg-red-700 hover:bg-red-700 rounded-md">
  <div class="animate-spin inline-block w-5 h-5 border-[3px] border-current border-t-transparent text-red-600 rounded-full" role="status" aria-label="loading">
  <span class="sr-only">Loading...</span>
</div>
  </button>
) : (
  <button
    className="px-4 py-2 font-sftext text-white bg-red-600 hover-bg-red-700 rounded-md"
    onClick={() => handleConfirmDelete(showDeletePopup)}
  >
    Delete
  </button>
)}
      </div>
    </div>
  </div>
)}
      <div className="">
        <Cards
          totalEnteries={totalEnteries}
          activeUsersCount={activeUsersCount}
          pendingUsersCount={pendingUsersCount}
        />
      </div>
      {/* <Cards totalEntries={totalEntries} /> */}

      <div class="w-[100%]  h-[100vh] bg-[#181818] table-bottom  xs:px-4 px-2 py-10 sm:px-6 lg:px-8 lg:pt-14 pb-20 mx-auto">
        <div class="flex flex-col">
          <div class=" lg:w-[100%] md:w-[90%] sm:w-[90%] w-[98%] lg:m-auto m-auto overflow-x-auto">
            <div class="xs:p-1.5 min-w-full sm:w-[100%] w-[80%] xs:w-[80%] inline-block align-middle">
              <div class="bg-black border sm:w-[100%] w-[100%] border-black rounded-xl shadow-sm overflow-hidden ">
                <div class="sm:px-6 px-2 py-4 grid gap-3 md:flex md:justify-between md:items-center   ">
                  <div>
                    <h2 class="text-xl font-semibold text-gray-200 ">Users</h2>
                    <p class="text-sm text-gray-300 ">
                      Add users, edit and more.
                    </p>
                  </div>

                  <div>
                    <div class="inline-flex gap-x-3">
                     
                      <div
                        class="hs-dropdown relative inline-block [--placement:bottom-right]"
                        data-hs-dropdown-auto-close="inside"
                      >
                        <button
                          id="hs-as-table-table-filter-dropdown"
                          type="button"
                          onClick={toggleDiv}
                          class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md  font-medium bg-[#181818] text-gray-200 shadow-sm align-middle  transition-all text-sm     "
                        >
                          <svg
                            class="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="white"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                          </svg>
                          {divVisible ? "Filter" : "Filter"}
                          <span class="pl-2 text-xs font-semibold text-gray-200 border-l border-gray-200  ">
                            1
                          </span>
                        </button>

                        {divVisible && (
                          <div className="absolute mt-1  bg-[#181818] left-0">
                            <div className="divide-y divide-gray-200 ">
                              <label
                                htmlFor="hs-as-filters-dropdown-all"
                                className="flex py-2 px-3"
                              >
                                <input
                                  type="checkbox"
                                  checked={showAll}
                                  onChange={() => handleCheckboxChange("all")}
                                  className="shrink-0 mt-0.5 rounded text-white pointer-events-none "
                                  id="hs-as-filters-dropdown-all"
                                />
                                <span className="ml-3 text-sm text-gray-200 dark:text-gray-200">
                                  All
                                </span>
                              </label>
                              <label
                                htmlFor="hs-as-filters-dropdown-active"
                                className="flex py-2 px-3"
                              >
                                <input
                                  type="checkbox"
                                  checked={showActive}
                                  onChange={() =>
                                    handleCheckboxChange("active")
                                  }
                                  className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 "
                                  id="hs-as-filters-dropdown-active"
                                />
                                <span className="ml-3 text-sm text-gray-200 dark:text-gray-200">
                                  Active
                                </span>
                              </label>
                              <label
                                htmlFor="hs-as-filters-dropdown-pending"
                                className="flex py-2 px-3"
                              >
                                <input
                                  type="checkbox"
                                  checked={showPending}
                                  onChange={() =>
                                    handleCheckboxChange("pending")
                                  }
                                  className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 "
                                  id="hs-as-filters-dropdown-pending"
                                />
                                <span className="ml-3 text-sm text-gray-200 dark:text-gray-200">
                                  Pending
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <table class="min-w-full min-h-[450px]  bg-black overflow-hidden border-b-0 rounded-md px-5 ">
                  <table class="min-w-full bg-black overflow-hidden border-b-0 rounded-md px-5 ">
                    <thead class="bg-[#202020] ">
                      <tr className="">
                        <th></th>

                        <th
                          scope="col"
                          class="sm:pl-6 pl-2  lg:pl-3 xl:pl-0 pr-6 py-3 text-left"
                        >
                          <div class="flex items-center  lg:ml-5  gap-x-2">
                            <span class="md:text-xs text-[10px] xs:text-sm font-semibold uppercase tracking-wide text-gray-200">
                              Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" class="px-6 w-[10%] py-3 text-left">
                          <div class="flex items-center gap-x-2">
                            <span class="md:text-xs ml-3 text-[10px] xs:text-sm  font-semibold uppercase tracking-wide text-gray-200 ">
                              Password
                            </span>
                          </div>
                        </th>
                     
                        <th scope="col" class="sm:px-6 py-3 text-left">
                          <div class="sm:flex xs:ml-4 md:ml-0 hidden  sm:justify-center sm:items-center gap-x-0">
                            <span class="md:text-xs text-[10px] xs:text-sm  font-semibold uppercase tracking-wide text-gray-200 dark:text-gray-200">
                              Status
                            </span>
                          </div>
                        </th>
                        {/* )} */}
                        <th scope="col" class="sm:px-6 py-3  text-left">
                          <div class="sm:flex xs:ml-4 md:ml-0 hidden  sm:justify-center sm:items-center gap-x-0">
                            <span class="md:text-xs text-[10px] xs:text-sm  font-semibold uppercase tracking-wide text-gray-200 dark:text-gray-200">
                              ID
                            </span>
                          </div>
                        </th>

                        <th scope="col" class="px-6  w-[15%] py-3 text-left">
                          <div class=" items-center md:flex hidden gap-x-2">
                            <span class="text-xs font-semibold uppercase tracking-wide text-gray-200 dark:text-gray-200">
                              Platform
                            </span>
                          </div>
                        </th>

                        <th scope="col" class="px-6 py-3 text-right"></th>
                      </tr>
                    </thead>

                    <tbody class="   ">
                      {currentItems.map((userData, index) => (
                        <tr
                          className=""
                          key={index}
                          style={{
                            display:
                              showAll ||
                              (showActive && userData.status === true) ||
                              (showPending && userData.status === false)
                                ? "table-row"
                                : "none",
                          }}

                       
                        >
                          <td class=" w-px ml-5 align-top"></td>
                          <td class=" lg:w-[20%] align-top w-[60%]">
                            <div class="sm:pl-6 pl-2 mt-2 lg:pl-3 xl:pl-0 pr-6 py-3">
                              <div class="flex lg:ml-4 items-center gap-x-3">
                                <img
                                  class="inline-block w-6 h-6 xs:h-[2.375rem] xs:w-[2.375rem] rounded-full"
                                  src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                                  alt="Image Description"
                                />
                                <div class="grow">
                                  <span class="block text-[12px] xs:text-sm font-semibold text-gray-200 dark:text-gray-200">
                                    {userData.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="w-72 align-top">
                            <div className="px-6 w-32 mt-4 ml-4 flex py-3">
                              {visiblePasswords[index] ? (
                                <span className="block text-[12px] xs:text-sm font-semibold text-gray-200 dark:text-gray-200">
                                  {userData.password}
                                </span>
                              ) : (
                                <span className="block text-[12px] xs:text-sm font-semibold text-gray-200 dark:text-gray-200">
                                  &bull;&bull;&bull;&bull;&bull;
                                </span>
                              )}
                              {visiblePasswords[index] ? (
                                <img
                                  className="xs:w-4 w-3 xs:mt-0 mt-1 ml-2 cursor-pointer"
                                  src={eye2} // Change the icon to eye2 when the password is visible
                                  onClick={() =>
                                    handlePasswordVisibility(index)
                                  }
                                />
                              ) : (
                                <img
                                  className="xs:w-4 w-3 xs:mt-0 mt-1 ml-2 cursor-pointer"
                                  src={eye} // Use the regular eye icon when the password is hidden
                                  onClick={() =>
                                    handlePasswordVisibility(index)
                                  }
                                />
                              )}
                            </div>
                          </td>

                          {/* // Update the rendering of the status in your table */}
                          <td class="w-px align-top">
                            <div class="sm:px-6 mt-4 cursor-pointer text-[12px] xs:text-sm md:text-lg sm:flex hidden sm:justify-center py-3">
                              {loading && loadingId === userData._id && (
                                <span
                                  onClick={() =>
                                    handleStatusClick(
                                      userData._id,
                                      userData.status
                                    )
                                  }
                                  className={`w-[78px] flex justify-center items-center  gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200`}
                                >
                                  <div className="w-full flex justify-center items-center">
                          <div class="animate-spin w-4 h-4 border-[3px] border-current border-t-transparent text-green-600 rounded-full" role="status" aria-label="loading">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                                  
                                </span>
                              )}
                              {userData.status === false &&
                                (!loading || loadingId !== userData._id) && (
                                  <span
                                    onClick={() =>
                                      handleStatusClick(
                                        userData._id,
                                        userData.status
                                      )
                                    }
                                    className={`inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200`}
                                  >
                                    <svg
                                      class="w-2.5 h-2.5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                   </svg>
                                    Pending
                                  </span>
                                )}
                              {userData.status === "pending" &&
                                (!loading || loadingId !== userData._id) && (
                                  <span
                                    onClick={() =>
                                      handleStatusClick(
                                        userData._id,
                                        userData.status
                                      )
                                    }
                                    className={`inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200`}
                                  >
                                    <svg
                                      class="w-2.5 h-2.5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg>
                                    Pending
                                  </span>
                                )}
                              {userData.status === true &&
                                (!loading || loadingId !== userData._id) && (
                                  <span
                                    onClick={() =>
                                      handleStatusClick(
                                        userData._id,
                                        userData.status
                                      )
                                    }
                                    className={`inline-flex items-center gap-1.5 py-0.5 px-3.5 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200`}
                                  >
                                    <svg
                                      class="w-2.5 h-2.5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                    Active
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="w-[20%]">
                            <div class="sm:flex xs:ml-4 hidden md:ml-0  sm:justify-center sm:items-center gap-x-0">
                              <span class="md:text-xs text-[10px] xs:text-sm  font-semibold uppercase tracking-wide text-gray-200 dark:text-gray-200">
                                {userData.orderId}
                              </span>
                            </div>
                          </td>

                          <td class="w-px md:flex hidden align-top">
                            <div class="px-6 py-6">
                              <span class="text-[12px] xs:text-sm md:text-lg text-gray-200">
                                {userData.platform}
                              </span>
                            </div>
                          </td>
                          <td class=" w-px  align-top">
                            <div class="pr-5 lg:flex hidden mt-6 py-1.5">
                              <img
                                className="sm:w-5 w-3 xs:w-4 xs:ml-0 ml-2 sm:h-5 xs:h-4 cursor-pointer"
                                src={copy}
                                alt="Copy"
                                onClick={() =>
                                  handleCopyCredentials(
                                    `${userData.email} - ${userData.password}`,
                                    index
                                  )
                                }
                                style={{
                                  opacity: copiedImageIndex === index ? 0.5 : 1,
                                }}
                              />

                              <img
                               className="sm:w-5 w-3  ml-5 h-3 xs:w-4 xs:ml-0 lg:ml-5 sm:h-5 xs:h-4 cursor-pointer"
                               src={trash}
                              
                              alt="Delete"
                              onClick={() => setShowDeletePopup(userData._id)}
                            />
                            </div>
                          </td>

                  
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </table>

                {/* Pagination Buttons */}

                <div class="flex justify-end px-5 mb-5 gap-x-2">
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    className={`${
                      currentPage > 1 ? "prev-button" : "prev-button-disabled"
                    } py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md  font-medium bg-[#2C2C2C] text-gray-200 shadow-sm align-middle hover:bg-[#2C2C2C]  transition-all text-sm  `}
                  >
                    <svg
                      className="w-3 h-3"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="white"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                      />
                    </svg>
                    Prev
                  </button>
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={`${
                      currentPage < totalPages
                        ? "next-button"
                        : "next-button-disabled"
                    } py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md  font-medium bg-[#2C2C2C] text-gray-200 shadow-sm align-middle hover:bg-[#2C2C2C] transition-all text-sm   `}
                  >
                    Next
                    <svg
                      className="w-3 h-3"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="white"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
