import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import whatsappQR from "../assets/whatsapp svg.svg";
import whatsapp from "../assets/whatsappimg.svg";
import telegram from "../assets/telegramimg.svg";
import telegramQR from "../assets/telegram QR SVG.svg";
function Welcome() {
  return (
    <div className="w-[100%] bg-[#121212] overflow-hidden xs:flex xs:flex-col flex flex-col  xs:pt-0 pt-56 xs:justify-center items-center h-[100vh]">
      <Link to="/thankyou/faq">
        <p
          className="font-circular text-[#1ED760]  absolute left-[80%] sm:left-[90%] top-[2%]
       text-[24px]"
        >
          FAQ
        </p>
      </Link>
      <h1 className=" lg:text-[48px] font-circular text-[#1ED760]  md:text-[40px] sm:text-3xl xs:text-xl 3xl:text-6xl text-2xl 3xl:leading-[80px]  lg:leading-[60px] mx-auto text-center items-center leading-10">
        Spotify Upgrade
      </h1>
      <p className="lg:text-[28px]  xs:px-28 px-14 font-circularlight mt-5  text-gray-200 md:text-[20px] xs:text-sm xs:leading-[20px]  sm:text-lg  lg:leading-[36px] 3xl:text-4xl 3xl:leading-[80px] text-sm leading-[20px]  mx-auto text-center items-center ">
        {" "}
        We are currently processing your Spotify upgrade request and will
        provide you with confirmation once it is completed.
      </p>
      <Link to="/">
        <button
          type="button"
          className="md:py-3.5 py-2  sm:w-[100%] tracking-wider mt-5 px-10 text md:text-[16px] inline-flex justify-center items-center gap-2 rounded-full border border-transparent font-medium md:font-semibold bg-[#1ED760] text-black transition-all text-[12px] sm:text-sm dark:focus:ring-offset-gray-800"
        >
          Return
        </button>
      </Link>

      <div className="text-white mt-5 xs:text-[12px]  text-[11px] sm:text-[18px] font-circularlight">
        <p>In case of any issue please contact our customer support here:</p>

      </div>
      <div className="mt-5 flex ">
        <div className="flex flex-col items-center">
          <Link to="https://wa.me/4915203831345?qr=1">
            <img className="w-9 mr-2 h-10" src={whatsapp} />
          </Link>
          <Link to="https://wa.me/4915203831345?qr=1">

            <img className="w-32 h-32" src={whatsappQR} />
          </Link>
        </div>
        <div className="flex flex-col items-center">
          <Link to="t.me/reupgrademe">
            <img className="w-10 h-10" src={telegram} />
          </Link>
          <Link to="t.me/reupgrademe">
            <img className="w-32 h-32" src={telegramQR} />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
