// // store/index.js
// import { createStore, combineReducers } from 'redux';
// import tokenReducer from '../reducers/Tokenreducer';
// import adminReducer from '../reducers/Tokenreducer';

// const rootReducer = combineReducers({
//   token: tokenReducer,
//   admin: adminReducer, // Add the admin reducer
//   // Add other reducers here if needed
// });

// const store = createStore(rootReducer);

// export default store;
import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use the storage engine of your choice

import tokenReducer from '../reducers/Tokenreducer';
import adminReducer from '../reducers/Tokenreducer'; // Assuming you have an AdminReducer

const rootReducer = combineReducers({
  token: tokenReducer,
  admin: adminReducer,
  // Add other reducers here if needed
});

const persistConfig = {
  key: 'root', // This key is used to store your state in storage.
  storage, // The storage engine to use.
  // Add any additional configuration options as needed.
  // whitelist: ['token'], // Specify which reducers to persist.
  // blacklist: ['admin'], // Specify which reducers not to persist.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);

export const persistor = persistStore(store); // Export the persistor

export default store;
