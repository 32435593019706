import React from 'react'
import Welcome from '../components/Welcome'

function User() {
  return (
    <div>
      <Welcome/>
    </div>
  )
}

export default User
