import React from 'react'
import Header from '../components/Header'
import Selectsource from '../components/Selectsource'

function Selectsrc() {
  return (
    <div className='custom-scrollbar h-[100vh] bg-[#1C1C1C]'>
      <Header/>
      <Selectsource/>
    </div>
  )
}

export default Selectsrc
