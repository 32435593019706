import React , {useEffect} from 'react'
import Header from '../components/Header'
import LoginScreen from '../components/LoginScreen'

function Login() {
  useEffect(() => {
    // Add the viewport meta tag to disable zooming
    const viewport = document.querySelector('meta[name="viewport"]');
    viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
  
    // The rest of your code...
  }, []);
  return (
    <div className='bg-[#121212] overflow-hidden  custom-scrollbar  font-circular' >
     <Header/>
     <LoginScreen/>
    </div>
  )
}

export default Login
