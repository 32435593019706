// reducers/adminReducer.js
const initialState = {
    adminToken: null,
  };
  
  const Tokenreducers = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ADMIN_TOKEN':
        return {
          ...state,
          adminToken: action.token,
        };
      case 'CLEAR_ADMIN_TOKEN':
        return {
          ...state,
          adminToken: null,
        };
      default:
        return state;
    }
  };
  
  export default Tokenreducers;