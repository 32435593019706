import React from 'react'
import Header from '../components/Header'
import Selectlist from '../components/Selectlist'

function Selectplaylist() {
  return (
    <div className='custom-scrollbar'>
      <Header/>
     <Selectlist/>
    </div>
  )
}

export default Selectplaylist
