import React from "react";
import { useEffect ,useState } from "react";
import axios from "axios";
function Selectlist() {
    const [playlistURL, setPlaylistURL] = useState('');
    const [playListData, setPlayListData] = useState('');
console.log(playlistURL)
const URL ="https://open.spotify.com/playlist/3JpxgD2E5LA3Sbx11iVfAs?si=e948ebd76ce84e16"
  const fetchPlaylist = async () => {
    console.log("in apii")
    console.log(URL)
    try {
        console.log("in apii")
        const playlist = await axios.post(
            "http://localhost:9000/getPlaylistData",
            {
            playListUri : URL,
        },
      );
      console.log("playsdfdfgdh",playlist);
      const response = playlist.data.Data
      setPlayListData(response)
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
console.log("playListData",playListData);
  const handleURLChange = (event) => {
    setPlaylistURL(event.target.value);
  };
  return (
    <div className=" bg-[#1C1C1C] h-[100vh] py-12 ">
      <div class="sm:w-1/2 flex justify-center items-center xl:w-1/3 mx-auto text-center mb-6 md:mb-12">
        <h2 class="text-xl font-semibold md:text-5xl md:leading-tight text-white dark:text-gray-200">
          Select the source
        </h2>
      </div>
      <div className="  mx-auto  bg-black  rounded-3xl  w-[60%]">
        <h1 className="text-white flex justify-center text-[30px] font-bold items-center py-5">
          Spotify Playlist
        </h1>
        <div className="w-[100%] flex  py-10">
          <div className="w-[50%] px-10 flex justify-center items-center">
            <button className="text-md rounded-full w-[100%] btn font-bold py-2 flex items-center justify-center">
              Load From Spotify acount
            </button>
          </div>
          <div className="w-[50%] px-10 border-l ">
            <h1 className="text-[16px] text-white">
              Copy Spotify playlist URL and paste here:
            </h1>
            <input
              className="bg-[#1C1C1C] text-white w-[100%] my-4 px-5 border rounded-md py-3 placeholder:text-[18px]"
              type="text"
              placeholder="Paste Spotify Playlist URL here"
              value={playlistURL}
              onChange={handleURLChange}
            />
            <button
              onClick={fetchPlaylist}
              className="text-md rounded-full mt-4 w-[100%] bg-[#1ED760] font-bold py-2 flex items-center justify-center"
            >
              Load From URL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Selectlist;
