import React from 'react'
import { Link } from 'react-router-dom'
function Hero() {
  return (
    <div>
  
<div class="">
  <div class="">
    <div class=" mx-auto bg-[#1C1C1C] px-4 sm:px-6 lg:px-8 py-6 space-y-8">
   
      
      <div class="w-[70%] text-center mx-auto">
        <h1 class="block font-bold text-white tracking-wide text-4xl sm:text-5xl md:text-6xl lg:text-6xl lg:leading-[71px]">
        Transfer Playlists Between Music Services
        </h1>
      </div>
      <div className='w-[80%] flex justify-between'>


      </div>
    

      <div class="w-[50%] text-center mx-auto">
        <p class="text-[16px] text-white">Transfer your music library from any music service to any other you want! Supports Spotify, TIDAL, Apple Music, YouTube, Amazon Music, Deezer and many more</p>
      </div>

    
      <div class="text-center">
        <Link to='/mymusic/selectmusic'>
        <a class="inline-flex justify-center w-[25%]  items-center gap-x-3 text-center bg-[#1ED760] border border-transparent text-black text-2xl font-medium rounded-full  py-5 px-6 " href="#">
          Get started
         
        </a>
        </Link>
      </div>
     
    </div>
  </div>
</div>

    </div>
  )
}

export default Hero
