// actions/adminActions.js
export const setAdminToken = (token) => {
    return {
      type: 'SET_ADMIN_TOKEN',
      token,
    };
  };
  
  export const clearAdminToken = () => {
    console.log('object')
    return {
      type: 'CLEAR_ADMIN_TOKEN',
    };
  };