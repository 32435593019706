// PrivateRoute.js
import React from 'react';
import { Navigate, Route } from 'react-router-dom';



function PrivateRoute({ element, token, redirect }) {
  return token ? (
    element
  ) : (
    <Navigate to={redirect} replace state={{ from: window.location.pathname }} />
  );
}

export default PrivateRoute;
